<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
        <v-hover v-slot="{ hover }" v-if="checkPipeline(card.label)">
          <v-card
            :disabled="!checkPipeline(card.label)"
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            :to="card.to"
          >
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="300px"
            >
              <v-card-title v-text="card.title"></v-card-title>
              <v-card-subtitle
                class="text-left"
                v-text="card.subtitle"
              ></v-card-subtitle>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'

export default {
  data: () => ({
    transparent: 'rgba(255, 255, 255, 0)',
  }),
  computed: {
    ...mapGetters({
      checkPipeline: 'user/checkPipeline',
    }),
    cards() {
      return [
        {
          label: 'CMR',
          title: i18n.t('sideBarLinks.CMR'),
          subtitle: i18n.t('sideBarLinksTooltips.CMR'),
          // subtitle: 'Įvykiai, kai apgadintas krovinys',
          src: require('../assets/images/home/CMR.jpg'),
          flex: 6,
          to: '/cmrdashboard',
        },
        {
          label: 'MTPL',
          title: i18n.t('sideBarLinks.MTPL'),
          subtitle: i18n.t('sideBarLinksTooltips.MTPL'),
          // subtitle: 'Civilinės atsakomybės įvykiai, kuriuose esate kalti',
          src: require('../assets/images/home/MTPL.jpg'),
          flex: 6,
          to: '/mtpldashboard',
        },
        {
          label: 'MTPL Injured',
          title: i18n.t('sideBarLinks["MTPL Injured"]'),
          subtitle: i18n.t('sideBarLinksTooltips["MTPL Injured"]'),
          // subtitle: 'Civilinės atsakomybės įvykiai, kuriuose esate nukentėję',
          src: require('../assets/images/home/MTPLI.jpg'),
          flex: 6,
          to: '/mtplinjureddashboard',
        },
        {
          label: 'CASCO',
          title: i18n.t('sideBarLinks.CASCO'),
          subtitle: i18n.t('sideBarLinksTooltips.CASCO'),
          // subtitle: 'KASKO žala Jūsų transporto priemonei',
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 6,
          to: '/cascodashboard',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
